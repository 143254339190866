import React, { useState } from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';

function SelectExpDate({ onExpDateChange }) {
    const [expDate, setExpDate] = useState('3');

    const handleSelectChange = (event) => {
        setExpDate(event.target.value);
        onExpDateChange(event.target.value);
    };

    return (
        <div>
            <FormControl sx={{ m: 1, minWidth: 160 }} size="large">
                <InputLabel id="exp-date-label">Expiration date</InputLabel>
                <Select
                    labelId="exp-date-label"
                    value={expDate}
                    onChange={handleSelectChange}
                >
                    <MenuItem value="3">3 mesi</MenuItem>
                    <MenuItem value="6">6 mesi</MenuItem>
                    <MenuItem value="12">1 anno</MenuItem>
                </Select>
            </FormControl>
        </div>
    );
}

export default SelectExpDate;