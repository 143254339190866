import React from 'react';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Button} from '@mui/material';
import Container from 'react-bootstrap/Container';



const styles = {
    card: {
        backgroundColor: '#f2f2f2',
        borderRadius: 10,
        padding: '3rem',
        height: '100%'
    },
    cardImage: {
        objectFit: 'cover',
        borderRadius: 15,
        width: '25%',
    }
}


function SelectBaseOs(props) {
    var ret;

    const back = () => {
        //e.preventDefault();
        props.prevStep();
    }

    const onClickOsLinux = () => {
        props.handleSelectOs('linux')
    }

    const onClickOsWindows = () => {
        props.handleSelectOs('windows')
    }


    ret = (
        <Container>
            <Form>
                <Form.Group controlId="formLabRequestBaseImageOs" style={{ width: "100%" }}>
                    <Form.Label>Wizard per la scelta dell'immagine base</Form.Label>

                    <Card style={{ padding: '3rem' }}>


                        <Row xs={1} md={2} className="g-4">

                            <Col md={5}>
                                <Card
                                    style={styles.card}
                                >
                                    <Card.Img variant="top" src="/reqLabOsWin.png" style={styles.cardImage} />
                                    <Card.Body>
                                        <Card.Title>Windows</Card.Title>
                                        <Card.Text>
                                            Selezionando questa opzione il laboratorio si baserà su sistema operativo Windows.
                                        </Card.Text>
                                    </Card.Body>
                                    <Button variant="contained" onClick={onClickOsWindows}>Seleziona</Button>
                                </Card>
                            </Col>
                            <Col md={5}>
                                <Card
                                    style={styles.card}
                                >
                                    <Card.Img variant="top" src="/reqLabOsTux.png" style={styles.cardImage} />
                                    <Card.Body>
                                        <Card.Title>Linux</Card.Title>
                                        <Card.Text>
                                            Selezionando questa opzione il laboratorio si baserà su sistema operativo Linux.
                                        </Card.Text>
                                    </Card.Body>
                                    <Button variant="contained" onClick={onClickOsLinux}>Seleziona</Button>
                                </Card>
                            </Col>
                            <Col md={2}>
                                <Row>
                                    <div class="shadow-none p-1 mb-5 bg-light rounded w-100" >1. Datacenter:
                                        <br/>
                                        <b>{props.statusForm.datacenter === 'unimib' ? 'Unimib' : 'Cloud'}</b>
                                    </div>
                                </Row>
                                <Row>
                                    <div class="shadow-lg p-1 mb-5 bg-white rounded w-100">2. Sistema operativo</div>
                                </Row>
                                <Row>
                                    <div class="shadow-none p-1 mb-5 bg-light rounded w-100">3. Tipo immagine</div>
                                </Row>
                                <Row>
                                    <div class="shadow-none p-1 mb-5 bg-light rounded w-100">4. Selezione immagine</div>
                                </Row>
                                <Row>
                                    <Button md={3} variant="contained" onClick={back} >Indietro</Button>
                                </Row>
                            </Col>

                        </Row>

                    </Card>

                    <br />
                </Form.Group>

            </Form>
        </Container>

    );

    return ret;
}

export default SelectBaseOs;