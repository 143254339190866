import React, { useState, useEffect, useCallback, useMemo } from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { Form, Col, Row } from 'react-bootstrap';
import {
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';



const crypto = require("crypto-browserify");


function SelectLib(props) {
    const [isLoading, setIsLoading] = useState(1)
    const [dataLib, setDataLib] = useState([]);
    const [searchLib, setSearchLib] = useState("");
    const [chiave, setChiave] = useState("");
    const [chiaveDes, setChiaveDes] = useState("Selezionare chiave");
    const [isKeySelected, setIsKeySelected] = useState(false);
    const [reloadData, setReloadData] = useState(false);
    const [rowSelection, setRowSelection] = useState([]);

    const { allocationMode, labServicesVirtualmachines, setStudents, setPhysicalLabs, apiAuthResponse } = props;

    const columnsLib = useMemo(
        () => [
            {
                accessorKey: "idLabInventory",
                header: "ID",
                size: "12",
            },
            {
                accessorKey: "nome",
                header: "LAB",
                size: "120",
            },
            {
                accessorKey: "edificio",
                header: "Edificio",
                size: "150",
            },
            {
                accessorKey: "computerName",
                header: "Computer Name",
                size: "150",
            },
            {
                accessorKey: "macAddress",
                header: "MAC Address",
                size: "150",
            },
            {
                accessorKey: "ip",
                header: "IP",
                size: "150",
            },
        ],
        [],
    );


    var ret;

    const searchLibHandler = (lib) => {
        setSearchLib(lib.target.value);
    }

    const selectChiave = (key, description) => {
        setChiave(key);
        setChiaveDes(description);
        setIsKeySelected(true);
    }

    const defineUser = useCallback((computerName, macAddress, ip, userType, labServicesVirtualmachineIdx) => {
        var randomUpperChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        var randomSpecialChars = '!#$-.';


        var myLabServices;

        if (typeof labServicesVirtualmachines === "object" && labServicesVirtualmachines.length > 0) {
            myLabServices = labServicesVirtualmachines[labServicesVirtualmachineIdx];
        }

        var myUser = {
            //samAccountName: todayStr + crypto.randomBytes(3).toString('hex'),
            email: (computerName + "-" + crypto.randomBytes(6).toString('hex')).slice(0, 19),
            quotaConsumed: 0,
            userType: userType,
            password: crypto.randomBytes(7).toString('hex') +
                randomUpperChars.charAt(Math.floor(Math.random() * randomUpperChars.length)) +
                randomSpecialChars.charAt(Math.floor(Math.random() * randomSpecialChars.length)),
            teacher: {
                email: apiAuthResponse,
                partition: 0
            },
            postazione: {
                macAddress: macAddress,
                ip: ip,
                computerName: computerName
            },
            labServices: myLabServices
        }

        //console.log("debug defineUser: " + JSON.stringify(myUser))

        return myUser;
    }, [apiAuthResponse, labServicesVirtualmachines])




    useEffect(() => {
        var userType = 'exam';
        if (typeof labServicesVirtualmachines === "object" && labServicesVirtualmachines.length > 0) {
            userType = 'labServices';
        }
        setStudents([]);

        let labNamesArray = [];
        var k = 0;

        const selectedLabs = dataLib.flatMap((item, i) =>
            item.postazioni.flatMap((postazione, j) => {
                //console.log("DEBUG indice lab : " + i + " indice postazione: " + j + "indice  " + k );
                if (rowSelection[`${i}.${j}`]) {
                    // Salva il nome del lab
                    labNamesArray.push(item.nome);
                    var myStudent;
                    if (allocationMode === "choose" || allocationMode === "all") {
                        myStudent = defineUser(postazione.computerName, postazione.macAddress, postazione.ip, userType, k);
                        setStudents(prevStudents => [...prevStudents, myStudent]);
                        k++;
                        return [postazione.computerName];
                    } else if (allocationMode === "even") {
                        if (j % 2 === 0) {
                            myStudent = defineUser(postazione.computerName, postazione.macAddress, postazione.ip, userType, k);
                            setStudents(prevStudents => [...prevStudents, myStudent]);
                            k++;
                            return [postazione.computerName];
                        } else {
                            const newRowSelection = { ...rowSelection };
                            delete newRowSelection[`${i}.${j}`];
                            setRowSelection(newRowSelection);
                            return [];
                        }
                    } else if (allocationMode === "odd") {
                        if (j % 2 !== 0) {
                            myStudent = defineUser(postazione.computerName, postazione.macAddress, postazione.ip, userType, k);
                            setStudents(prevStudents => [...prevStudents, myStudent]);
                            k++;
                            return [postazione.computerName];
                        } else {
                            const newRowSelection = { ...rowSelection };
                            delete newRowSelection[`${i}.${j}`];
                            setRowSelection(newRowSelection);
                            return [];
                        }
                    }
                }
                k++;
                return [];

            })
        );
        console.log("DEBUG selectedLabs: " + JSON.stringify(selectedLabs));

        // Rimuovi i duplicati e salva lo state
        labNamesArray = [...new Set(labNamesArray)];
        setPhysicalLabs(labNamesArray);
        console.log("DEBUG labNamesArray: " + JSON.stringify(labNamesArray));


    }, [rowSelection, allocationMode, labServicesVirtualmachines, setStudents, setPhysicalLabs, dataLib, defineUser]);




    useEffect(() => {
        var formBody = [];
        var payload = {
            key: chiave,
            value: searchLib
        }

        function fetchData() {
            setTimeout(async () => {
                setIsLoading(1);

                formBody.push("payload=" + JSON.stringify(payload));
                await fetch(process.env.REACT_APP_URL_LIBINVENTORY_GET, {
                    credentials: 'include',
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                    },
                    body: formBody
                })
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((data) => {
                        setDataLib(data);
                    })
                    .catch((error) => {
                        console.log(error, "Errore!")
                    });

                setIsLoading(0);
            });

        }

        setDataLib([])
        setReloadData(false);

        fetchData();

    }, [searchLib, chiave, reloadData]);


    const onSubmit = (e) => {
        e.preventDefault();
        //var formBody = [];
        //var url;
    }


    const table = useMaterialReactTable({
        columns: columnsLib,
        data: dataLib || [],

        defaultDisplayColumn: { enableResizing: true },
        enableColumnResizing: true,
        enableRowActions: false,

        enableRowSelection: true,


        enableExpandAll: false, //hide expand all double arrow in column header
        enableExpanding: true,
        filterFromLeafRows: true, //apply filtering to all rows instead of just parent rows
        getSubRows: (row) => row.postazioni,
        initialState: {
            expanded: false,
            pagination: { pageIndex: 0, pageSize: 20 },
        },
        paginateExpandedRows: false,
        muiTableContainerProps: { sx: { maxHeight: '800px' } },
        onRowSelectionChange: setRowSelection,
        state: { rowSelection },

    });


    if (!dataLib) {
        ret = null;
    } else {
        ret = (
            <React.Fragment>

                <h2 className="title-page">Selezionare i LIB (Laboratori fisici) per lo svolgimento dell'esame </h2>

                <Form onSubmit={onSubmit}>
                    <Form.Group controlId="searchLib" style={{ width: "85%" }}>
                        <Form.Label>Ricerca LIB</Form.Label>
                        <Row>
                            <Col>
                                <Form.Text className="text-muted">Selezionare la chiave</Form.Text>
                            </Col>
                            <Col>
                                <Form.Text className="text-muted">Inserire il Valore</Form.Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <DropdownButton id="dropdown-basic-button" title={chiaveDes}>
                                    <Dropdown.Item onClick={() => selectChiave("nome", "Nome LIB")}>Nome LIB</Dropdown.Item>
                                    <Dropdown.Item onClick={() => selectChiave("edificio", "Edificio")}>Edificio</Dropdown.Item>
                                    <Dropdown.Item onClick={() => selectChiave("subnet", "Subnet")}>Subnet</Dropdown.Item>
                                    <Dropdown.Item onClick={() => selectChiave("ip", "IP")}>IP</Dropdown.Item>
                                </DropdownButton>
                            </Col>
                            <Col >
                                <Form.Control style={{ width: "100%" }}
                                    required
                                    type="text"
                                    onChange={searchLibHandler}
                                    //placeholder={chiave}
                                    value={searchLib}
                                    readOnly={!isKeySelected}
                                />
                            </Col>
                            <Col >
                                <ClipLoader
                                    size={25}
                                    color={"#a71e3b"}
                                    loading={isLoading}
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                </Form>

                <MaterialReactTable table={table} />


            </React.Fragment>
        );
    }



    return ret;

}

export default SelectLib